module.exports = {
  games: {
    answer_submission_error: 'Antwort konnte nicht übermittelt werden. Bitte neu auswählen.',
    time_has_passed: 'Die Zeit ist abgelaufen',
    audio_no_browser_support: 'Dein Browser unterstützt das Audio Element nicht.',
    challenge_bot: 'Bot herausfordern',
    choose_category: 'Kategorie wählen',
    bot_difficulty_1: 'Anfänger',
    bot_difficulty_2: 'Fortgeschritten',
    choose_random_opponent: 'Zufälliger Gegner',
    game_status_aborted_short: 'Abgebrochen',
    next_round: 'Nächste Runde:',
    opponent_reached_max_game_limit: 'Du hast bereits ein aktives Spiel mit diesem User. Wähle einen anderen Spieler. | Limit an gleichzeitigen Spielen ({count} Spiele) mit diesem User erreicht. Wähle einen anderen Spieler.',
    round_n: 'Runde %{n}',
    submit_answer: 'Antwort abschicken',
    your_turn: 'Du bist am Zug',
    xes_turn: '%{username} ist am Zug',
    your_turn_emoji: '👉 Du bist am Zug!',
    you_lost_emoji: 'Du hast dieses Battle verloren! 🙈',
    challenge_revenge: 'Zur Revanche herausfordern',
    draw_emoji: 'Dieses Battle endete unentschieden ⚖️',
    challenge_again: 'Erneut herausfordern',
    you_won_emoji: 'Du hast dieses Battle gewonnen! 🎉',
    your_active_battles: 'Deine aktiven Quiz-Battles',
    show_finished_battles: 'Beendete Quiz-Battles anzeigen',
    draw_short: 'Unentschieden',
    win_short: 'Du hast gewonnen!',
    lost_short: 'Du hast verloren.',
    your_time_left: 'Du hast noch %{hours}h %{minutes}m %{seconds}s Zeit zu antworten.',
    opponents_turn_emoji: '⏳ %{opponentName} ist am Zug',
    opponents_time_left: 'Und hat noch %{hours}h %{minutes}m Zeit zu antworten.',
    startet_at: 'Gestartet am %{date} um %{time} Uhr',
    quiz_history: 'Beendete Quiz-Battles',
    quiz_stats: 'Quiz-Statistiken',
  },
  quizteams: {
    teamname: 'Teamname',
    add_new_member: 'Neues Mitglied hinzufügen',
    create_description: 'Gib hier den Namen deines Teams ein. Im nächsten Schritt kannst du Mitglieder einladen.',
    create_new_quizteam: 'Neues Quiz-Team erstellen',
    create_quizteam: 'Team erstellen',
    at_least_3_characters: 'Bitte gib mindestens 3 Buchstaben als Teamnamen ein.',
    team_details: 'Team details',
    create_new: 'Neues Quiz Team anlegen',
    all: 'Alle Teams',
    mine: 'Meine Teams',
    quizteams: 'Teams',
    add_new_member_description: 'Gib hier einen Benutzernamen ein um ein neues Mitglied hinzuzufügen',
    add_member_to_team: 'Zum Team hinzufügen',
    your_teams: 'Deine Quiz-Teams',
    no_teams_yet: 'Du bist noch keinem Team beigetreten. Bitte den Admin eines anderen Teams darum, dich aufzunehmen oder gründe dein eigenes Team und lade deine Kolleginnen und Kollegen ein.',
    show_my_teams: 'Meine Quiz Teams anzeigen',
    members: 'Mitglieder',
    add_member: 'Mitglied hinzufügen',
    admin: 'Admin',
  },
  questions: {
    suggest_new_question: 'Neue Frage vorschlagen',
    question: 'Frage',
    question_n: 'Frage %{n}',
    suggest_questions: 'Quiz-Fragen vorschlagen',
    your_notes: 'Deine Notizen',
  },
  statistics: {
    all_tags: 'Alle Tags',
    games_won: 'Spiele gewonnen',
    questions_correct: 'Fragen richtig',
    top_quiz_teams: 'Top Teams',
    top_players: 'Top Spieler',
    my_statistics: 'Meine Statistiken',
    show_all: 'Zu allen Statistiken',
    show_all_players: 'Alle Spieler anzeigen',
    show_all_teams: 'Alle Teams anzeigen',
    questions_correct_percentage: 'Anteil richtig',
    win_quote: 'Siegquote',
  },
  no_categories: 'Derzeit verfügst du über keine spielbaren Kategorien. Bitte wende dich an deinen Administrator.',
  no_categories_mail: 'Derzeit verfügst du über keine spielbaren Kategorien. Bitte wende dich an: %{adminMail}',
  answered_correctly: 'Richtig beantwortet!',
  answered_incorrectly: 'Das war leider nicht ganz richtig.',
  feedback: 'Auflösung',
  joker: '50:50 Joker 🔮',
  joker_used: '50:50 Joker genutzt',
  next_question: 'Nächste Frage',
  start_first_quiz: 'Starte jetzt dein erstes Quiz:',
  start_new_quiz: 'Neues Quiz-Battle starten',
  your_turn_choose_category: 'Du bist am Zug. Wähle eine Kategorie:',
  search_opponent: 'Gegner suchen',
  challenge: 'Herausfordern',
  new_random_opponent: 'Neuer Vorschlag',
  quiz_battle: 'Quiz-Battle',
  vs: 'vs',
  start_quiz: 'Quiz starten',
  start_round: 'Los gehts!',
  category_competition_label: 'Gewinnspiel 🏆',
}
